import { module } from 'modujs';
import Swup from 'swup';
import SwupFragmentPlugin from '@swup/fragment-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupA11yPlugin from '@swup/a11y-plugin';
import SwupModularPlugin from '../utils/swup/SwupModularPlugin';
import SwupLSPlugin from '../utils/swup/SwupLSPlugin';
import { CSS_CLASS, CUSTOM_EVENT } from '../config';
import { $body, $html } from '../utils/dom';
import fragmentRules from '../utils/swup/FragmentRules';

export default class extends module {
    constructor(m) {
        super(m);

        // Binding
        this.onVisitStart       = this.onVisitStart.bind(this);
        this.onContentReplace   = this.onContentReplace.bind(this);
        this.onAnimationInStart = this.onAnimationInStart.bind(this);
        this.onPageView         = this.onPageView.bind(this);
    }

    init() {
        this.load = new Swup({
            containers: ['[data-load-container]'],
            animateHistoryBrowsing: true,
            ignoreVisit: (url, { el, event } = {}) => {
                return el?.closest('[data-no-swup]')
            },
            // cache: false,
            plugins: [
                new SwupFragmentPlugin({
                    rules: fragmentRules,
                }),
                new SwupLSPlugin({ modularInstance: this }),
                new SwupModularPlugin({ modularInstance: this }),
                new SwupPreloadPlugin({ preloadInitialPage: false }),
                new SwupA11yPlugin({
                    announcements: {
                        'fr-FR': {
                            visit: 'Navigué vers : {title}',
                            url: 'Nouvelle page à {url}'
                        },
                        'fr-CA': {
                            visit: 'Navigué vers : {title}',
                            url: 'Nouvelle page à {url}'
                        },
                        '*': {
                            visit: 'Navigated to: {title}',
                            url: 'New page at {url}'
                        }
                    }
                })
            ]
        });

        this.load.hooks.on('visit:start', this.onVisitStart)
        this.load.hooks.on('content:replace', this.onContentReplace);
        this.load.hooks.on('animation:in:start', this.onAnimationInStart);
        this.load.hooks.on('page:view', this.onPageView);
    }

    // ==========================================================================
    // HOOKS
    // ==========================================================================

    onVisitStart(visit) {
        // Dispatch custom event
        window.dispatchEvent(new CustomEvent(CUSTOM_EVENT.VISIT_START, { detail: visit }))

        if(!visit.fragmentVisit) {
            $html.classList.remove(CSS_CLASS.READY)
            $html.classList.remove(CSS_CLASS.LOADED)
            $html.classList.add(CSS_CLASS.LOADING)
        } else {
            visit.a11y.focus = false;

            switch (visit.fragmentVisit.name) {
                case 'open-dialog':
                    this.call('show', null, 'Dialog')
                    break
                case 'close-dialog':
                    this.call('close', null, 'Dialog')
                    break;
            }
        }
    }

    onContentReplace(visit) {
        for(let module of ['Menu', 'ShareModal', 'VideoModal', 'Modal'])
            this.call('rebindTogglers', null, module);

        this.updateActiveClass();

        if(!visit.fragmentVisit) {
            $html.classList.remove(CSS_CLASS.LOADING)
            $html.classList.add(CSS_CLASS.LOADED)
        }
    }

    updateActiveClass() {
        const currentPath = window.location.pathname;
        const menuLinks = document.querySelectorAll('.c-header_links a');

        menuLinks.forEach(link => {
            const parentLi = link.closest('li');
            if (link.getAttribute('href') === currentPath) {
                parentLi.classList.add('active');
            } else {
                parentLi.classList.remove('active');
            }
        });
    }

    onAnimationInStart(visit) {
        if(!visit.fragmentVisit) {
            setTimeout(() => {
                $html.classList.add(CSS_CLASS.READY)
            }, 50)
        }
    }

    onPageView(visit) {
    }

    // ==========================================================================
    // METHODS
    // ==========================================================================

    goTo(url) {
        this.load.navigate(url)
    }
}
