export default fragmentRules = [
    {
        from: "/events",
        to: "/events/:event",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/events/:event",
        to: "/events",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    {
        from: "/podcasts",
        to: "/podcast/:podcast",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/podcast/:podcast",
        to: "/podcasts",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    {
        from: "/portraits",
        to: "/portraits/:portrait",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/portraits/:portrait",
        to: "/portraits",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    {
        from: "/articles",
        to: "/blog/:article",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/blog/:article",
        to: "/articles",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    // FEED
    {
        from: "/feed",
        to: "/events/:event",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/events/:event",
        to: "/feed",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    {
        from: "/feed",
        to: "/podcast/:podcast",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/podcast/:podcast",
        to: "/feed",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    {
        from: "/feed",
        to: "/portraits/:portrait",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/portraits/:portrait",
        to: "/feed",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    {
        from: "/feed",
        to: "/blog/:article",
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: "/blog/:article",
        to: "/feed",
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    // UPDATE DIALOG
    {
        from: "/podcast/:podcast",
        to: "/podcast/:podcast",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/podcast/:podcast",
        to: "/blog/:article",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/podcast/:podcast",
        to: "/portraits/:portrait",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/podcast/:podcast",
        to: "/events/:event",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/events/:event",
        to: "/events/:event",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/events/:event",
        to: "/podcast/:podcast",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/events/:event",
        to: "/blog/:article",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/events/:event",
        to: "/portraits/:portrait",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/portraits/:portrait",
        to: "/portraits/:portrait",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/portraits/:portrait",
        to: "/podcast/:podcast",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/portraits/:portrait",
        to: "/blog/:article",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/portraits/:portrait",
        to: "/events/:event",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/blog/:article",
        to: "/blog/:article",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/blog/:article",
        to: "/podcast/:podcast",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/blog/:article",
        to: "/portraits/:portrait",
        containers: ['#dialog'],
        name: 'update-dialog'
    }, {
        from: "/blog/:article",
        to: "/events/:event",
        containers: ['#dialog'],
        name: 'update-dialog'
    }
]